import React from "react"
import { useIntl } from "gatsby-plugin-react-intl"

//Styled components imports
import Wrapper from "./Wrapper"
import List from "./List"
import ListItem from "./ListItem"
import Link from "./Link"

const AboutShopping = () => {
  const intl = useIntl()

  return (
    <Wrapper>
      <List>
        <ListItem>
          <Link to="/vse-o-nakupu-doprava-a-platba">
            {intl.formatMessage({
              id: "pages.all_about_purchase.shipping_and_payment",
            })}
          </Link>
        </ListItem>
        <ListItem>
          <Link to="/vse-o-nakupu-reklamace-a-vraceni">
            {intl.formatMessage({
              id: "pages.all_about_purchase.complaints_and_returns",
            })}
          </Link>
        </ListItem>
        <ListItem>
          <Link to="/obchodni-podminky">
            {intl.formatMessage({
              id: "pages.all_about_purchase.terms_and_conditions",
            })}
          </Link>
        </ListItem>
        <ListItem>
          <Link to="/ochrana-osobnich-udaju">
            {intl.formatMessage({
              id: "pages.all_about_purchase.gdpr_personal_data_protection",
            })}
          </Link>
        </ListItem>
      </List>
    </Wrapper>
  )
}

export default AboutShopping
