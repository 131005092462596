import React from "react"
import { useIntl } from "gatsby-plugin-react-intl"

//React components imports
import Layout from "../components/Layout"

//React components imports
import AboutShopping from "../components/AboutShopping"
import Breadcrumbs from "../components/Breadcrumbs"

//Styled components imports
import Title from "../shared-styled-components/Title"

const AboutShoppingPage = ({ location }) => {
  const intl = useIntl()

  return (
    <Layout
      subtitle={intl.formatMessage({ id: "pages.all_about_purchase.title" })}
    >
      <Breadcrumbs
        data={{
          path: location.pathname.replace("/en", ""),
          breadEnd: intl.formatMessage({
            id: "pages.all_about_purchase,title",
          }),
        }}
      />
      <Title>
        {intl.formatMessage({ id: "pages.all_about_purchase.title" })}
      </Title>
      <AboutShopping />
    </Layout>
  )
}

export default AboutShoppingPage
